import React from "react";
import Img from "gatsby-image";
import Section from "../../components/Section/Section";

import "./HeroSimple.scss";

const HeroSimple = ({ title, backgroundImage }) => {
  return (
    <Section className="HeroSimple">
      <div className="container">
        <div className="row align-items-sm-start align-items-md-center">
          <div className="col-lg-12">
            <h1 className="Text--light" dangerouslySetInnerHTML={{ __html: title }} />
          </div>
        </div>
      </div>
      <div className="BgImageContainer">
        {backgroundImage && (
          <Img
            fluid={backgroundImage.localFile.childImageSharp.fluid}
            alt={backgroundImage.altText}
          />
        )}
      </div>
    </Section>
  );
};

export default HeroSimple;
